exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apps-index-js": () => import("./../../../src/pages/apps/index.js" /* webpackChunkName: "component---src-pages-apps-index-js" */),
  "component---src-pages-apps-tajer-customer-authentication-callback-[platform]-js": () => import("./../../../src/pages/apps/tajer-customer/authenticationCallback/[platform].js" /* webpackChunkName: "component---src-pages-apps-tajer-customer-authentication-callback-[platform]-js" */),
  "component---src-pages-apps-tajer-customer-customer-index-js": () => import("./../../../src/pages/apps/tajer-customer/customer/index.js" /* webpackChunkName: "component---src-pages-apps-tajer-customer-customer-index-js" */),
  "component---src-pages-apps-tajer-customer-dashboard-admin-temp-events-js": () => import("./../../../src/pages/apps/tajer-customer/dashboard/adminTempEvents.js" /* webpackChunkName: "component---src-pages-apps-tajer-customer-dashboard-admin-temp-events-js" */),
  "component---src-pages-apps-tajer-customer-dashboard-automated-campaigns-js": () => import("./../../../src/pages/apps/tajer-customer/dashboard/automatedCampaigns.js" /* webpackChunkName: "component---src-pages-apps-tajer-customer-dashboard-automated-campaigns-js" */),
  "component---src-pages-apps-tajer-customer-dashboard-automated-campaigns-performance-js": () => import("./../../../src/pages/apps/tajer-customer/dashboard/automatedCampaignsPerformance.js" /* webpackChunkName: "component---src-pages-apps-tajer-customer-dashboard-automated-campaigns-performance-js" */),
  "component---src-pages-apps-tajer-customer-dashboard-broadcasts-campaigns-invite-existing-customers-campaign-broadcast-id-js": () => import("./../../../src/pages/apps/tajer-customer/dashboard/broadcastsCampaigns/inviteExistingCustomersCampaign/[broadcastId].js" /* webpackChunkName: "component---src-pages-apps-tajer-customer-dashboard-broadcasts-campaigns-invite-existing-customers-campaign-broadcast-id-js" */),
  "component---src-pages-apps-tajer-customer-dashboard-broadcasts-campaigns-js": () => import("./../../../src/pages/apps/tajer-customer/dashboard/broadcastsCampaigns.js" /* webpackChunkName: "component---src-pages-apps-tajer-customer-dashboard-broadcasts-campaigns-js" */),
  "component---src-pages-apps-tajer-customer-dashboard-broadcasts-performance-js": () => import("./../../../src/pages/apps/tajer-customer/dashboard/broadcastsPerformance.js" /* webpackChunkName: "component---src-pages-apps-tajer-customer-dashboard-broadcasts-performance-js" */),
  "component---src-pages-apps-tajer-customer-dashboard-campaigns-convert-customers-campaign-index-js": () => import("./../../../src/pages/apps/tajer-customer/dashboard/campaigns/convertCustomersCampaign/index.js" /* webpackChunkName: "component---src-pages-apps-tajer-customer-dashboard-campaigns-convert-customers-campaign-index-js" */),
  "component---src-pages-apps-tajer-customer-dashboard-campaigns-engage-first-time-customers-campaign-index-js": () => import("./../../../src/pages/apps/tajer-customer/dashboard/campaigns/engageFirstTimeCustomersCampaign/index.js" /* webpackChunkName: "component---src-pages-apps-tajer-customer-dashboard-campaigns-engage-first-time-customers-campaign-index-js" */),
  "component---src-pages-apps-tajer-customer-dashboard-campaigns-engage-high-value-customers-campaign-index-js": () => import("./../../../src/pages/apps/tajer-customer/dashboard/campaigns/engageHighValueCustomersCampaign/index.js" /* webpackChunkName: "component---src-pages-apps-tajer-customer-dashboard-campaigns-engage-high-value-customers-campaign-index-js" */),
  "component---src-pages-apps-tajer-customer-dashboard-campaigns-engage-product-interested-customers-campaign-index-js": () => import("./../../../src/pages/apps/tajer-customer/dashboard/campaigns/engageProductInterestedCustomersCampaign/index.js" /* webpackChunkName: "component---src-pages-apps-tajer-customer-dashboard-campaigns-engage-product-interested-customers-campaign-index-js" */),
  "component---src-pages-apps-tajer-customer-dashboard-campaigns-popup-campaign-index-js": () => import("./../../../src/pages/apps/tajer-customer/dashboard/campaigns/popupCampaign/index.js" /* webpackChunkName: "component---src-pages-apps-tajer-customer-dashboard-campaigns-popup-campaign-index-js" */),
  "component---src-pages-apps-tajer-customer-dashboard-campaigns-welcome-and-invite-customers-campaign-index-js": () => import("./../../../src/pages/apps/tajer-customer/dashboard/campaigns/welcomeAndInviteCustomersCampaign/index.js" /* webpackChunkName: "component---src-pages-apps-tajer-customer-dashboard-campaigns-welcome-and-invite-customers-campaign-index-js" */),
  "component---src-pages-apps-tajer-customer-dashboard-index-js": () => import("./../../../src/pages/apps/tajer-customer/dashboard/index.js" /* webpackChunkName: "component---src-pages-apps-tajer-customer-dashboard-index-js" */),
  "component---src-pages-apps-tajer-customer-dashboard-settings-js": () => import("./../../../src/pages/apps/tajer-customer/dashboard/settings.js" /* webpackChunkName: "component---src-pages-apps-tajer-customer-dashboard-settings-js" */),
  "component---src-pages-apps-tajer-customer-login-js": () => import("./../../../src/pages/apps/tajer-customer/login.js" /* webpackChunkName: "component---src-pages-apps-tajer-customer-login-js" */),
  "component---src-pages-apps-tajer-customer-reset-password-js": () => import("./../../../src/pages/apps/tajer-customer/resetPassword.js" /* webpackChunkName: "component---src-pages-apps-tajer-customer-reset-password-js" */),
  "component---src-pages-apps-tajer-report-dashboard-js": () => import("./../../../src/pages/apps/tajer-report/dashboard.js" /* webpackChunkName: "component---src-pages-apps-tajer-report-dashboard-js" */),
  "component---src-pages-apps-tajer-report-index-js": () => import("./../../../src/pages/apps/tajer-report/index.js" /* webpackChunkName: "component---src-pages-apps-tajer-report-index-js" */),
  "component---src-pages-apps-tajer-report-privacy-policy-js": () => import("./../../../src/pages/apps/tajer-report/privacyPolicy.js" /* webpackChunkName: "component---src-pages-apps-tajer-report-privacy-policy-js" */),
  "component---src-pages-authentication-js": () => import("./../../../src/pages/authentication.js" /* webpackChunkName: "component---src-pages-authentication-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/resetPassword.js" /* webpackChunkName: "component---src-pages-reset-password-js" */)
}

