import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import styled from "styled-components";
import Button2 from "../button2";
export default function CookieConsestBox({ preventedThirdPartyCookie = false ,language}) {
  const [showList, setShowList] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  useEffect(() => {
    if (!document) return;
    document.cookie = "C=temp; Max-Age=2; Path=/";
    if (document.cookie.length <= 1) return setIsBlocked(true);
    // if(cookies['TPC']) return setIsBlocked(true);
    // else connect(true).get('/v1/TC/dashboard').then((res) => {
    // }).catch((err) => {
    //   if(err?.response?.status == 511)
    //   {setIsBlocked(true);
    //   document.cookie = "TPC=true; Max-Age=3600; Path=/";}
    // });
  }, []);

  if (!(isBlocked || preventedThirdPartyCookie)) return <div></div>;
  return (
    <CookieConsentBoxContainer>
      {preventedThirdPartyCookie ? (
        <h1
        id='supported'
          style={{
            position:'fixed',
            textAlign: "center",
            top: "30%",
            left: "30%",
            color: "var(--TCSecondaryColor)",
            fontWeight: "bolder",
          }}
        >
          إعدادات متصفحك غير مدعومة!!!
        </h1>
      ) : (
        ""
      )}
      <div id="cookie-consent-box">
        <div id="cookie-svg">
          <svg
            height="100"
            width="100"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <radialGradient
                id="cookie-gradient"
                cx="50%"
                cy="50%"
                r="50%"
                fx="50%"
                fy="50%"
              >
                <stop offset="0%" stopColor="#f5d6a4" />
                <stop offset="100%" stopColor="#e6b781" />
              </radialGradient>
            </defs>
            <circle cx="50" cy="50" r="45" fill="url(#cookie-gradient)" />
            <circle cx="30" cy="30" r="2" fill="#8c4f1b" />
            <circle cx="40" cy="20" r="3" fill="#8c4f1b" />
            <circle cx="60" cy="25" r="2" fill="#8c4f1b" />
            <circle cx="70" cy="35" r="3" fill="#8c4f1b" />
            <circle cx="80" cy="45" r="2" fill="#8c4f1b" />
            <circle cx="65" cy="55" r="3" fill="#8c4f1b" />
            <circle cx="50" cy="65" r="2" fill="#8c4f1b" />
            <circle cx="35" cy="75" r="3" fill="#8c4f1b" />
            <circle cx="20" cy="60" r="2" fill="#8c4f1b" />
          </svg>
        </div>
        <div id="cookie-text">
          <h1>
            {language == 'ar' ? "تطبيقنا يستخدم الكوكيز" : <Trans>We use cookies</Trans>}{" "}
            <div className="smallCookie"> 🍪 </div>
          </h1>
          <p>
            {" "}
            {language == 'ar' ? " يستخدم هذا الموقع ملفات تعريف الارتباط لتحسين تجربة التصفح لديكم، لن يعمل التطبيق لديك كما يجب إن لم تسمح له باستخدام الكوكيز، قم بتفعيلها باتباع الارشادات الخاصة بمتصفحك التي سنزودك بها . أو تواصل مع الدعم" : <Trans>
              This site uses cookies to improve your browsing experience. The
              application will not work properly for you if you do not allow it
              to use cookies. Activate it by following the instructions of your
              browser which we provided you with. or cantact the SUPPORT
            </Trans>}
          </p>
          <p>
          {language == 'ar' ? " اعدادات متصفحك حاليا تمنع التطبيق من استخدام الكوكيز" :<Trans>
              Your browser settings currently prevent the application from using
              cookies.
            </Trans> }
          </p>
        </div>

        {!showList ? (
          <div id="cookie-button">
            <Button2
              onClick={() => setShowList(true)}
              text={language == 'ar' ? "كيفية تفعيل الكوكيز" : <Trans>Activate cookies</Trans>}
            />
          </div>
        ) : (
          <div id="browser-links">
            <p>
              {language == 'ar' ? "الرجاء اختيار نوع متصفحك لمعرفة كيف يمكنك تفعيل الكوكيز" :<Trans>
                Please select your browser to change your cookie settings
              </Trans> }
              :
            </p>
            <ul>
              <li>
                <a target="_blank" href="https://support.google.com/chrome/answer/95647">
                  جوجل كروم - Google Chrome
                </a>
              </li>
              <li>
                <a target="_blank" href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer">
                  فايرفوكس - Mozilla Firefox{" "}
                </a>
              </li>
              <li>
                <a target="_blank" href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d">
                  ايدج - Microsoft Edge{" "}
                </a>
              </li>
              <li>
                <a target="_blank" href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">
                  سفاري - Safari
                </a>
              </li>
              <li>
                <a target="_blank" href="https://help.opera.com/en/latest/web-preferences/#cookies">
                  اوبرا - Opera
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </CookieConsentBoxContainer>
  );
}

const CookieConsentBoxContainer = styled.div`
  #cookie-consent-box {
    z-index: 999;
    background: linear-gradient(
      145deg,
      rgba(241, 212, 214, 1) 0%,
      rgba(255, 255, 255, 1) 45%,
      rgba(241, 212, 214, 1) 50%,
      rgba(255, 255, 255, 1) 98%,
      rgba(241, 212, 214, 1) 100%
    );
    position: fixed;
    bottom: 0;
    /* left: 0; */
    right: 0;
    margin: 20px;
    padding: 1rem;
    width: 900px;
    border: 1px solid #ccc;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    animation: slide-up 0.5s ease-in-out;
    gap: 1.4rem;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  }

  #cookie-svg {
    width: 100px;
    height: 100px;
    margin-right: 0.51rem;
    animation: rotate 2s linear infinite;
  }

  #cookie-text {
    flex: 1;
    font-size: 16px;
    color: #333;
  }

  #cookie-text h1 {
    font-size: 24px;
    font-weight: bold;
    margin: 0 0 10px 0;
    color: #5c5c5c;
  }

  #cookie-text p {
    margin: 0;
    line-height: 1.5;
    width: 96%;
    :nth-child(3) {
      font-weight: bold;
    }
  }

  #cookie-text a {
    color: #333;
    text-decoration: underline;
  }

  #cookie-button {
    display: flex;
    justify-content: center;
  }
  #cookie-button,
  #browser-links {
    width: 190px;
    li {
      line-height: 1.3rem;
    }
    p,
    a {
      font-weight: bold;
      font-size: 0.8rem;
    }
    a {
      color: var(--TCPrimaryColor);
      :hover {
        color: var(--TCSecondaryColor);
      }
    }
  }

  @keyframes slide-up {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0%);
    }
  }

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .smallCookie {
    scale: 1.2;
    animation: puls 0.31s ease-in-out infinite alternate-reverse;
    display: inline-block;
  }

  @keyframes puls {
    from {
      transform: scale(0.98);
    }
    to {
      transform: scale(1);
    }
  }
  #supported{
    animation: nion 1s ease-in-out infinite alternate-reverse;
  }
  
  @keyframes nion {
    from {
      opacity: .6;
      filter: brightness(.63);
    }
    to {
      opacity: 1;
      filter: brightness(1.3);
    }
  }
  @media screen and (max-width:500px){
    #cookie-consent-box {
    z-index: 999;
    width: 350px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 1.4rem;
  }
  }
`;
