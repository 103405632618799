import { wrapRootElementC } from "./src/lib/RQuery";
import React from "react";
import CookieConsestBox from "./src/common/components/cookieConsestBox.js/cookieConsestBox";
import {createRoot} from 'react-dom/client';

export const wrapRootElement = wrapRootElementC;
export const onClientEntry = () => {
  
    !navigator.cookieEnabled && createRoot(document.getElementById('ThisIsToInjectCookieBoxIdWhichExistInAppWrapperTC')).render(<CookieConsestBox language={'ar'} />);
    if (!localStorage?.getItem('gatsby-i18next-language')) {
    // Set the default language to Arabic (ar)
    localStorage?.setItem('gatsby-i18next-language', 'ar');
    }
  };